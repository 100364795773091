export const getSimpleSuccesses = (data) => [
  {
    type: 'accept-workspace-success',
    message: 'You have accepted the invitation.',
  }, {
    type: 'accept-workspace-transfer-success',
    message: 'You have accepted the transfer request for a new workspace.',
  }, {
    type: 'add-balance-success',
    message: 'Your balance is successfully added.',
  }, {
    type: 'add-cname-success',
    message: 'Record is successfully added.',
  }, {
    type: 'add-credit-card-success',
    message: 'Your card is successfully added.',
  }, {
    type: 'add-error-code-success',
    message: 'Your custom error page rule is successfully created.',
  }, {
    type: 'add-origin-success',
    message: 'Your origin is successfully added.',
  }, {
    type: 'add-origin-server-success',
    message: 'A server to your origin is successfully added.',
  }, {
    type: 'add-page-rule-success',
    message: 'Your page rule is successfully added.',
  }, {
    type: 'cancel-workspace-transfer-success',
    message: 'You have cancelled the transfer request.',
  }, {
    type: 'copy-success',
    message: 'Successfully copied!',
  }, {
    type: 'copy-certificate-success',
    message: 'Your certificate has been successfully copied to the target workspace!',
  }, {
    type: 'create-cache-invalidation-success',
    message: 'Cache invalidation request has been received.',
  }, {
    type: 'create-cache-prefetching-success',
    message: 'Cache prefetching request has been received.',
  }, {
    type: 'create-certificate-success',
    message: 'Your certificate is successfully added.',
  }, {
    type: 'get-free-ssl-certificate-success',
    message: 'Free certificate is successfully added.',
  }, {
    type: 'create-distribution-success',
    message: 'Your distribution is successfully created. You\'re now being redirected to your distribution\'s details page.',
  }, {
    type: 'create-workspace-success',
    message: 'Your workspace is successfully created.',
  }, {
    type: 'create-error-page-success',
    message: 'Your error page rule is successfully created.',
  }, {
    type: 'create-role-success',
    message: 'The new custom role is successfully created.',
  }, {
    type: 'create-waf-custom-rule-success',
    message: 'Your custom rule is successfully created.',
  }, {
    type: 'toggle-waf-custom-rule-success',
    message: 'Your custom rule is successfully updated.',
  }, {
    type: 'reorder-waf-custom-rule-success',
    message: 'Your custom rule is successfully reordered.',
  }, {
    type: 'create-waf-list-success',
    message: 'Your list is successfully created.',
  }, {
    type: 'create-waf-policy-success',
    message: 'Your policy is successfully created.',
  }, {
    type: 'create-workspace-alert-success',
    message: 'The new notification is successfully created.',
  }, {
    type: 'delete-certificate-success',
    message: 'Your certificate is successfully deleted.',
  }, {
    type: 'delete-cname-success',
    message: 'Selected record is deleted.',
  }, {
    type: 'delete-credit-card-success',
    message: 'Your card is deleted.',
  }, {
    type: 'delete-distribution-success',
    message: 'Your distribution is successfully deleted.',
  }, {
    type: 'delete-error-code-success',
    message: 'Your custom error page rule is deleted.',
  }, {
    type: 'delete-error-page-success',
    message: 'Selected error page rule is deleted.',
  }, {
    type: 'delete-origin-success',
    message: 'Your origin is successfully deleted.',
  }, {
    type: 'delete-origin-server-success',
    message: 'A server from your origin is successfully deleted.',
  }, {
    type: 'delete-page-rule-success',
    message: 'Your page rule is deleted.',
  }, {
    type: 'delete-role-success',
    message: 'The custom role is successfully deleted.',
  }, {
    type: 'delete-user-success',
    message: 'The workspace user is successfully deleted.',
  }, {
    type: 'delete-waf-custom-rule-error',
    message: 'Your custom rule is successfully deleted.',
  }, {
    type: 'delete-waf-list-content-success',
    message: 'The item is successfully deleted.',
  }, {
    type: 'delete-waf-list-success',
    message: 'The list is successfully deleted.',
  }, {
    type: 'delete-waf-policy-success',
    message: 'The policy is successfully deleted.',
  }, {
    type: 'delete-workspace-success',
    message: 'Your workspace is deleted.',
  }, {
    type: 'leave-workspace-success',
    message: 'You are no longer in this workspace.',
  }, {
    type: 'delete-workspace-alert-success',
    message: 'The notification is successfully deleted.',
  }, {
    type: 'disable-distribution-raw-logs-success',
    message: 'Raw Logs are successfully disabled for your distribution.',
  }, {
    type: 'disable-user-api-success',
    message: 'Your API key is successfully disabled.',
  }, {
    type: 'duplicate-waf-policy-success',
    message: 'The policy is successfully duplicated.',
  }, {
    type: 'enable-distribution-raw-logs-success',
    message: 'Raw Logs are successfully enabled for your distribution.',
  }, {
    type: 'enable-user-api-success',
    message: 'Your API key is successfully enabled.',
  }, {
    type: 'disable-totp-success',
    message: 'TOTP is successfully disabled.',
  }, {
    type: 'activate-totp-success',
    message: 'TOTP is successfully enabled.',
  }, {
    type: 'verify-totp-success',
    message: 'TOTP is successfully verified.',
  }, {
    type: 'invite-user-success',
    message: 'The user is successfully invited.',
  }, {
    type: 'create-admin-microservice-success',
    message: 'The microservice is successfully added.',
  }, {
    type: 'update-admin-microservice-detail',
    message: 'The microservice detail is successfully updated.',
  }, {
    type: 'import-waf-list-success',
    message: 'Your file is successfully imported. Duplicates and items after the limit have been removed.',
  }, {
    type: 'make-cname-default-success',
    message: 'The record you chose is made default.',
  }, {
    type: 'make-credit-card-default-success',
    message: 'The card you chose is made default.',
  }, {
    type: 'move-distribution-success',
    message: 'The distribution has been moved to the chosen workspace.',
    action: {
      href: `/${data.orgID}/${data.wsID}/distributions`,
      text: `Go To ${data.name}`,
    },
  }, {
    type: 'plan-license-activate-success',
    message: 'Your license has been activated!',
  }, {
    type: 'plan-license-revoke-success',
    message: 'Your license has successfully been revoked and regenerated!',
  }, {
    type: 'reject-workspace-success',
    message: 'You have rejected the invitation.',
  }, {
    type: 'reject-workspace-transfer-success',
    message: 'You have rejected the transfer request for a new workspace.',
  }, {
    type: 'resend-success',
    message: 'Verification mail is resent to your saved email!',
  }, {
    type: 'start-workspace-transfer-success',
    message: 'You have started a transfer request for the workspace. The transfer now waits for approval.',
  }, {
    type: 'update-billing-settings-success',
    message: 'Your invoice settings are successfully updated.',
  }, {
    type: 'update-auto-payment-success',
    message: 'Auto Top-up settings are successfully updated.',
  }, {
    type: 'enable-auto-payment-success',
    message: 'Auto Top-up is successfully enabled.',
  }, {
    type: 'disable-auto-payment-success',
    message: 'Auto Top-up is successfully disabled.',
  }, {
    type: 'purchase-plan-success',
    message: 'You have successfully updated the selected plan.',
  }, {
    type: 'send-referral-link-success',
    message: 'You have successfully sent a referral link.',
  }, {
    type: 'update-certificate-success',
    message: 'Your certificate is successfully updated.',
  }, {
    type: 'update-cname-success',
    message: 'Selected record is updated.',
  }, {
    type: 'update-configuration-success',
    message: 'Your distribution\'s configuration is successfully updated.',
  }, {
    type: 'update-distribution-status-success',
    message: 'Your distribution\'s status is successfully updated.',
  }, {
    type: 'update-distribution-waf-policy-success',
    message: 'Your distribution\'s WAF Policy is successfully updated.',
  }, {
    type: 'update-email-success',
    message: 'Your email is successfully updated.',
  }, {
    type: 'update-phone-no-success',
    message: 'Your phone number is successfully updated.',
  }, {
    type: 'update-error-code-success',
    message: 'Your custom error page rule is successfully updated.',
  }, {
    type: 'update-error-page-success',
    message: 'Your error page rule is successfully updated.',
  }, {
    type: 'update-general-settings-success',
    message: 'Your distribution\'s general settings are successfully updated.',
  }, {
    type: 'update-me-success',
    message: 'Your profile details are successfully updated!',
  }, {
    type: 'update-organization-settings-success',
    message: 'Your organization is successfully updated.',
  }, {
    type: 'update-origin-success',
    message: 'Your origin is successfully updated.',
  }, {
    type: 'update-origin-server-success',
    message: 'A server of your origin is successfully updated.',
  }, {
    type: 'update-page-rule-success',
    message: 'Your page rule is successfully updated.',
  }, {
    type: 'update-restrictions-success',
    message: 'Your distribution\'s restrictions are successfully updated.',
  }, {
    type: 'update-role-success',
    message: 'The custom role is successfully updated.',
  }, {
    type: 'update-waf-list-success',
    message: 'The list is successfully updated.',
  }, {
    type: 'update-waf-list-content-success',
    message: `${data.count === 1 ? `${data.count} item` : `${data.count} items`} have been added to the list. Invalid items have been removed, and duplicate items have been overwritten.`,
  }, {
    type: 'update-waf-custom-rule-success',
    message: 'The custom rule is successfully updated.',
  }, {
    type: 'update-waf-managed-rule-success',
    message: 'The rule status is successfully updated.',
  }, {
    type: 'update-waf-policy-success',
    message: 'The policy is successfully updated.',
  }, {
    type: 'update-workspace-success',
    message: 'The workspace is successfully updated.',
  }, {
    type: 'update-workspace-user-success',
    message: 'The user\'s role is successfully updated.',
  }, {
    type: 'update-workspace-alert-success',
    message: 'The notification is successfully updated.',
  }, {
    type: 'refresh-user-api-success',
    message: 'Your API key is successfully refreshed.',
  },
  {
    type: 'verify-phone-no-success',
    message: 'Your phone number is successfully updated.',
  },
  {
    type: 'resend-code-success',
    message: 'The verification code resubmitted.',
  },
  {
    type: 'resend-code-errors',
    message: 'Failed to send the verification code.',
  },
  {
    type: 'activate-storage-success',
    message: 'Storage successfully activated.',
  },
  {
    type: 'create-key-success',
    message: 'The key is successfully created.',
  },
  {
    type: 'delete-key-success',
    message: 'The key is successfully deleted.',
  },
  {
    type: 'create-bucket-success',
    message: 'Bucket is successfully created.',
  },
  {
    type: 'create-object-success',
    message: 'Object is successfully created.',
  },
  {
    type: 'create-folder-success',
    message: 'Folder is successfully created.',
  },
  {
    type: 'delete-object-success',
    message: 'The Object is successfully deleted.',
  },
  {
    type: 'delete-multiple-object-success',
    message: 'The Objects are successfully deleted.',
  },
  {
    type: 'rename-object-success',
    message: 'Object is successfully renamed.',
  },
  {
    type: 'set-object-versioning-success',
    message: 'Versioning is successfully updated.',
  },
  {
    type: 'upload-object-success',
    message: 'Upload succeeded.',
  },
  {
    type: 'set-bucket-tagging-success',
    message: 'The tags are successfully updated.',
  },
  {
    type: 'delete-bucket-tagging-success',
    message: 'The tags are successfully deleted.',
  },
  {
    type: 'set-object-versioning-success',
    message: 'Successfully updated tags.',
  },
  {
    type: 'add-distribution-redirection-success',
    message: 'The rule is successfully created.',
  },
  {
    type: 'update-distribution-redirection-success',
    message: 'The rule is successfully updated.',
  },
  {
    type: 'delete-distribution-redirection-success',
    message: 'The rule is successfully deleted.',
  },
  {
    type: 'update-bucket-acl-success',
    message: 'Visibility is successfully updated.',
  },
  {
    type: 'update-object-acl-success',
    message: 'Visibility is successfully updated.',
  },
  {
    type: 'public-link-copied-successfully',
    message: 'The public link is successfully copied.',
  },
  {
    type: 'set-bucket-website-success',
    message: 'Successfully edited static website hosting.',
  },
  {
    type: 'update-policy',
    message: 'Successfully edited bucket policy.',
  },
  {
    type: 'delete-bucket-website-success',
    message: 'Successfully deleted static website hosting.',
  },
  {
    type: 'edit-key-success',
    message: 'Key successfully updated.',
  },
  {
    type: 'cloner-job-create-success',
    message: 'Job successfully created.',
  },
  {
    type: 'cloner-job-delete-success',
    message: 'Job successfully deleted.',
  },
  {
    type: 'cloner-job-activate-success',
    message: 'Job successfully enabled.',
  },
  {
    type: 'cloner-job-deactivate-success',
    message: 'Job successfully disabled.',
  },
  {
    type: 'cloner-job-trigger-success',
    message: 'Job successfully triggered.',
  },
  {
    type: 'object-url-coppied',
    message: 'Object URL successfully copied.',
  },
  {
    type: 'admin__user__updated',
    message: 'User successfully updated.',
  },
  {
    type: 'admin__user__inform',
    message: 'Notification email sent successfully.',
  },
  {
    type: 'admin_organization_updated',
    message: 'Organization successfully updated.',
  },
  {
    type: 'admin_workspace_updated',
    message: 'Workspace successfully updated.',
  },
  {
    type: 'admin_server_updated',
    message: 'Server successfully updated.',
  },
  {
    type: 'admin_server_deleted',
    message: 'Server successfully deleted.',
  },
  {
    type: 'admin_server_created',
    message: 'Server successfully created.',
  },
  {
    type: 'admin_ws_transfer_cancel',
    message: 'Workspace transfer operation canceled.',
  },
  {
    type: 'admin_ws_transfer_start',
    message: 'Workspace transfer operation started.',
  },
  {
    type: 'payment-success',
    message: 'Order successfully completed!',
  },
  {
    type: 'plan-downgraded-success',
    message: 'Plan successfully scheduled for downgrading.',
  },
  {
    type: 'plan-canceled-success',
    message: 'Plan scheduled for cancellation.',
  },
  {
    type: 'future-action-delete-success',
    message: 'Request successfully canceled.',
  },
  {
    type: 'resend-totp-success',
    message: 'Your new code has been sent.',
  },
  {
    type: 'totp-setup-complete',
    message: 'Two-factor authentication successfully enabled.',
  },
  {
    type: 'totp-disable-complete',
    message: 'Two-factor authentication successfully disabled.',
  },
  {
    type: 'organization-limit-update-complete',
    message: 'Organization Limit Updated.',
  },
  {
    type: 'organization-service-update-complete',
    message: 'Organization Service Updated.',
  },
  {
    type: 'organization-limit-set-default-complete',
    message: 'Organization limit has been updated with the default value for the relevant service.',
  },
  {
    type: 'make_admin_otp_send',
    message: 'OTP codes have been sent to your email addresses.',
  },
  {
    type: 'make_admin_otp_verify',
    message: 'User type updated to Admin.',
  },
];

export const simpleErrors = [
  'admin_server_created_error',
  'admin_service_created_error',
  'admin_user_update_error',
  'admin_organization_update_error',
  'admin_workspace_update_error',
  'admin_server_update_error',
  'admin_invite_user_error',
  'admin_service_detail_upsert_error',
  'admin_ws_transfer_prepare_error',
  'admin_ws_transfer_cancel_error',
  'admin_ws_transfer_start_error',
  'make_admin_otp_send_error',
  'make_admin_otp_verify_error',
  'generic-error',
  'accept-workspace-error',
  'accept-workspace-transfer-error',
  'add-balance-error',
  'add-cname-error',
  'add-credit-card-error',
  'add-error-code-error',
  'add-origin-error',
  'add-origin-server-error',
  'add-page-rule-error',
  'cancel-workspace-transfer-error',
  'copy-certificate-error',
  'copy-error',
  'create-cache-invalidation-error',
  'create-cache-prefetching-error',
  'create-certificate-error',
  'create-workspace-alert-error',
  'get-free-ssl-certificate-error',
  'get-workspace-alert-error',
  'create-distribution-error',
  'create-error-page-error',
  'create-role-error',
  'create-waf-list-error',
  'create-waf-custom-rule-error',
  'toggle-waf-custom-rule-error',
  'reorder-waf-custom-rule-error',
  'create-waf-policy-error',
  'create-workspace-error',
  'enable-distribution-raw-logs-error',
  'enable-user-api-error',
  'delete-certificate-error',
  'delete-cname-error',
  'delete-credit-card-error',
  'delete-distribution-error',
  'delete-error-code-error',
  'delete-error-page-error',
  'delete-origin-error',
  'delete-origin-server-error',
  'delete-page-rule-error',
  'delete-role-error',
  'delete-user-error',
  'delete-waf-list-error',
  'delete-waf-list-content-error',
  'delete-waf-custom-rule-error',
  'delete-waf-policy-error',
  'delete-workspace-error',
  'delete-workspace-alert-error',
  'disable-distribution-raw-logs-error',
  'disable-user-api-error',
  'duplicate-waf-policy-error',
  'enable-totp-error',
  'disable-totp-error',
  'activate-totp-error',
  'verify-totp-error',
  'get-tax-information-error',
  'get-billing-plans-error',
  'invite-user-error',
  'leave-workspace-error',
  'make-cname-default-error',
  'make-credit-card-default-error',
  'move-distribution-error',
  'plan-license-activate-error',
  'plan-license-revoke-error',
  'reject-workspace-error',
  'reject-workspace-transfer-error',
  'resend-error',
  'start-workspace-transfer-error',
  'update-billing-settings-error',
  'get-auto-payment-error',
  'update-auto-payment-error',
  'enable-auto-payment-error',
  'disable-auto-payment-error',
  'purchase-plan-error',
  'send-referral-link-error',
  'update-certificate-error',
  'update-cname-error',
  'update-configuration-error',
  'update-distribution-status-error',
  'update-distribution-waf-policy-error',
  'update-email-error',
  'update-phone-no-error',
  'update-error-code-error',
  'update-error-page-error',
  'update-general-settings-error',
  'update-me-error',
  'update-organization-settings-error',
  'update-origin-error',
  'update-origin-server-error',
  'update-page-rule-error',
  'update-restrictions-error',
  'update-role-error',
  'update-waf-list-error',
  'update-waf-list-content-error',
  'update-waf-custom-rule-error',
  'update-waf-managed-rule-error',
  'update-waf-policy-error',
  'update-workspace-error',
  'update-workspace-user-error',
  'update-workspace-alert-error',
  'refresh-user-api-error',
  'summary-endpoint-error',
  'rule-drag-drop-error',
  'verify-phone-no-success',
  'verify-phone-no-error',
  'resend-code-success',
  'resend-code-error',
  'activate-storage-error',
  'create-key-error',
  'delete-key-error',
  'create-bucket-error',
  'create-folder-error',
  'create-object-error',
  'upload-object-error',
  'delete-object-error',
  'delete-multiple-object-error',
  'rename-object-error',
  'set-object-versioning-error',
  'set-bucket-tagging-error',
  'delete-bucket-tagging-error',
  'add-distribution-redirection-error',
  'delete-distribution-redirection-error',
  'delete-bucket-not-empty-error',
  'presign-downlaod-error',
  'presign-url-error',
  'set-bucket-website-error',
  'delete-bucket-website-error',
  'set-policy-error',
  'edit-key-error',
  'cloner-job-delete-error',
  'cloner-job-activate-error',
  'cloner-job-deactivate-error',
  'cloner-job-trigger-error',
  'subscribe-cart-detail-error',
  'subscribe-cart-plan-error',
  'future-action-create-error',
  'future-action-delete-error',
  'future-action-fetch-error',
  'totp-error',
  'totp-resend-error',
  'totp-init-error',
  'totp-complete-error',
  'totp-disable-error',
];
