import { ADMIN_ACTIONS } from './Admin.actions';

export default (state = null, action) => {
  switch (action.type) {
    case ADMIN_ACTIONS.USER_REQUEST:
      return {
        ...state,
        users: action.payload,
      };
    case ADMIN_ACTIONS.WORKSPACE_REQUEST:
      return {
        ...state,
        workspaces: action.payload,
      };
    case ADMIN_ACTIONS.ORGANIZATION_REQUEST:
      return {
        ...state,
        organizations: action.payload,
      };
    case ADMIN_ACTIONS.ALL_ORGANIZATION_REQUEST:
      return {
        ...state,
        allOrganizations: action.payload,
      };
    case ADMIN_ACTIONS.USER_DETAIL_REQUEST:
      return {
        ...state,
        userDetail: action.payload.data,
      };
    case ADMIN_ACTIONS.USER_ORGANIZATION_REQUEST:
      return {
        ...state,
        organizationDetail: action.payload.data,
      };
    case ADMIN_ACTIONS.ORGANIZATION_LIMIT_REQUEST:
      return {
        ...state,
        organizationLimits: action.payload.data,
      };
    case ADMIN_ACTIONS.ORGANIZATION_DETAIL_UPDATE_REQUEST:
      return {
        ...state,
        organizationLimit: action.payload.data,
      };
    case ADMIN_ACTIONS.ORGANIZATION_LIMIT_DEFAULT_REQUEST:
      return {
        ...state,
        organizationLimit: action.payload.data,
      };
    case ADMIN_ACTIONS.WORKSPACE_DETAIL_REQUEST:
      return {
        ...state,
        workspaceDetail: action.payload.data,
      };
    case ADMIN_ACTIONS.WORKSPACE_ACTIVITY_FILTER:
      return {
        ...state,
        workspaceActivity: state.workspaceActivity ? [...state.workspaceActivity, action.payload.data] : [action.payload.data],
        nextActivity: action.payload.meta.next,
      };
    case ADMIN_ACTIONS.RESET_WORKSPACE_ACTIVITY:
      return { ...state, workspaceActivity: [], nextActivity: undefined };
    case ADMIN_ACTIONS.SERVER_REQUEST:
      return {
        ...state,
        servers: action.payload,
      };
    case ADMIN_ACTIONS.SERVER_DETAIL_REQUEST:
      return {
        ...state,
        serverDetail: action.payload.data,
      };
    case ADMIN_ACTIONS.SERVER_ROLE_REQUEST:
      return {
        ...state,
        serverRoles: action.payload,
      };
    case ADMIN_ACTIONS.ZONE_REQUEST:
      return {
        ...state,
        zones: action.payload,
      };
    case ADMIN_ACTIONS.SERVICES_REQUEST:
      return {
        ...state,
        services: action.payload,
      };
    case ADMIN_ACTIONS.SERVICES_DETAIL_UPDATE_REQUEST:
      return {
        ...state,
        serviceDetailData: action.payload,
      };
    case ADMIN_ACTIONS.GET_SERVICE:
      return {
        ...state,
        serviceDetail: action.payload.data,
      };
    case ADMIN_ACTIONS.SERVICE_HEALTH_CHECK_REQUEST:
      return {
        ...state,
        serviceHealthCheckLogs: action.payload,
      };
    case ADMIN_ACTIONS.CREATE_SERVICE:
      return {
        ...state,
        serviceDetail: action.payload.data,
      };
    case ADMIN_ACTIONS.SERVICE_LOG_REQUEST:
      return {
        ...state,
        serviceLogs: action.payload,
      };
    case ADMIN_ACTIONS.GET_WORKSPACE_USERS:
      return {
        ...state,
        workspaceUsers: action.payload,
      };
    case ADMIN_ACTIONS.INVITE_WORKSPACE_USERS:
      return {
        ...state,
        inviteWorkspaceUser: action.payload,
      };
    case ADMIN_ACTIONS.DELETE_WORKSPACE_USERS:
      return {
        ...state,
        deleteWorkspaceUser: action.payload,
      };
    case ADMIN_ACTIONS.WORKSPACE_TRANSFER_REQUEST:
      return {
        ...state,
        workspaceTransfers: action.payload,
      };
    case ADMIN_ACTIONS.PREPARE_WORKSPACE_TRANSFER_REQUEST:
      return {
        ...state,
        prepareWorkspaceTransfer: action.payload,
      };
    case ADMIN_ACTIONS.CANCEL_WORKSPACE_TRANSFER_REQUEST:
      return {
        ...state,
        cancelWorkspaceTransfer: action.payload,
      };
    case ADMIN_ACTIONS.START_WORKSPACE_TRANSFER_REQUEST:
      return {
        ...state,
        startWorkspaceTransfer: action.payload,
      };
    case ADMIN_ACTIONS.LIMIT_REQUEST:
      return {
        ...state,
        limits: action.payload,
      };
    default:
      return state;
  }
};
